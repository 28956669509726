/* You can add global styles to this file, and also import other style files */

@import '~animate.css/animate.min.css';
@import '~ngx-toastr/toastr';
@import "~@ng-select/ng-select/themes/default.theme.css";
.modal.show .modal-dialog {
    top: 70px;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: none;
}

.page-item.active .page-link {
    color: var(--primary);
}

.ng-select .ng-select-container {
    border: none;
    border-radius: 22px;
    background: var(--bg-color2);
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
    background: transparent;
}


/* //style for map avatar */

#doctor_map {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    align-self: center;
}

#doctor_map::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--primary);
    opacity: 0.3;
}

#doctor_map img {
    width: 47px;
    height: 47px;
    border: 3px solid var(--primary);
    border-radius: 50%;
    margin: auto;
    align-self: center;
    position: relative;
    z-index: 99;
}

.ng-dropdown-panel.ng-select-top,
.ng-dropdown-panel.ng-select-bottom {
    position: relative !important;
    top: 0!important;
    display: block;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}

ng-select.ng-select-opened {
    padding-bottom: 10px !important;
}

ng-select .ng-select-container {
    box-shadow: none !important;
}

.qr_code_popup .qr_code_popup_inner qrcode .qrcode svg {
    margin: 0 auto;
    display: block;
    border-radius: 20px;
}

.qr_code_popup .qr_code_popup_inner qrcode {
    position: absolute;
    /* top: 0; */
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    /* height: fit-content; */
    /* top: 40px; */
    top: 50px;
    height: auto;
}

.qr_code_popup .qr_code_popup_inner qrcode .qrcode canvas {
    margin: 0 auto;
    display: block;
}

.timepicker-backdrop-overlay {
    background: rgb(62 62 62 / 59%) !important;
    backdrop-filter: blur(7px)!important;
}

.timepicker__header {
    position: unset !important;
    background: var(--primary)!important;
}

.timepicker-dial {
    text-align: center !important;
    display: block!important;
    margin: 0 auto!important;
    width: fit-content!important;
}

.clock-face__number>span.active {
    background-color: var(--primary) !important;
}

.clock-face__clock-hand {
    background-color: var(--primary) !important;
}

.clock-face__clock-hand:after {
    background-color: var(--primary) !important;
}

.clock-face__clock-hand_minute:before {
    border-color: var(--primary) !important;
}

.timepicker {
    background: var(--white) !important;
    border-radius: 10px !important;
    overflow: hidden !important;
    padding: 0px 0 14px 0 !important;
}

.timepicker__body {
    padding: 15px 20px !important;
}

.timepicker__actions {
    justify-content: normal !important;
    width: fit-content !important;
    margin: 0 auto !important;
    padding: 0 !important;
}

ngx-material-timepicker-button .timepicker-button {
    color: var(--white)!important;
    background: var(--primary)!important;
    border-radius: 50px !important;
    margin: 0 10px;
}